:root,
.rs-theme-light {
    --rs-gray-0: #fff;
    --rs-gray-50: #f7f7fa;
    --rs-gray-100: #f2f2f5;
    --rs-gray-200: #e5e5ea;
    --rs-gray-300: #d9d9d9;
    --rs-gray-400: #c5c6c7;
    --rs-gray-500: #a6a6a6;
    --rs-gray-600: #8e8e93;
    --rs-gray-700: #7a7a7a;
    --rs-gray-800: #575757;
    --rs-gray-900: #272c36;
    --rs-primary-50: #f2faff;
    --rs-primary-100: #cce9ff;
    --rs-primary-200: #a6d7ff;
    --rs-primary-400: #59afff;
    --rs-primary-500: #3498ff;
    --rs-primary-600: #2589f5;
    --rs-primary-700: #1675e0;
    --rs-primary-800: #0a5dc2;
    --rs-primary-900: #004299;
    --rs-color-red: #f44336;
    --rs-red-400: #f7635c;
    --rs-red-500: #f44336;
    --rs-red-600: #eb3626;
    --rs-orange-400: #fa9b2f;
    --rs-orange-500: #fa8900;
    --rs-orange-600: #f08800;
    --rs-yellow-400: #ffbe30;
    --rs-yellow-500: #ffb300;
    --rs-yellow-600: #f5af00;
    --rs-green-400: #65bf67;
    --rs-green-500: #4caf50;
    --rs-green-600: #37ab3c;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-blue-400: #49abf5;
    --rs-blue-500: #2196f3;
    --rs-blue-600: #1787e8;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-text-primary: var(--rs-gray-800);
    --rs-text-secondary: var(--rs-gray-600);
    --rs-text-active: var(--rs-primary-700);
    --rs-text-disabled: var(--rs-gray-600);
    --rs-text-error: var(--rs-color-red);
    --rs-border-primary: var(--rs-gray-200);
    --rs-border-secondary: var(--rs-gray-100);
    --rs-bg-overlay: var(--rs-gray-0);
    --rs-bg-well: var(--rs-gray-50);
    --rs-bg-active: var(--rs-primary-500);
    --rs-state-hover-bg: var(--rs-primary-50);
    --rs-color-focus-ring: rgb(from var(--rs-primary-500) r g b / 25%);
    --rs-btn-default-bg: var(--rs-gray-50);
    --rs-btn-default-text: var(--rs-gray-800);
    --rs-btn-default-hover-bg: var(--rs-gray-200);
    --rs-btn-default-active-bg: var(--rs-gray-300);
    --rs-btn-default-active-text: var(--rs-gray-900);
    --rs-btn-default-disabled-bg: var(--rs-gray-50);
    --rs-btn-default-disabled-text: var(--rs-gray-600);
    --rs-btn-primary-bg: var(--rs-primary-500);
    --rs-btn-primary-text: var(--rs-gray-0);
    --rs-btn-primary-hover-bg: var(--rs-primary-600);
    --rs-btn-primary-active-bg: var(--rs-primary-700);
    --rs-btn-subtle-text: var(--rs-gray-800);
    --rs-btn-subtle-hover-bg: var(--rs-gray-200);
    --rs-btn-subtle-hover-text: var(--rs-gray-800);
    --rs-btn-subtle-active-bg: var(--rs-gray-200);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-subtle-disabled-text: var(--rs-gray-400);
    --rs-btn-ghost-border: var(--rs-primary-700);
    --rs-btn-ghost-text: var(--rs-primary-700);
    --rs-btn-ghost-hover-border: var(--rs-primary-800);
    --rs-btn-ghost-hover-text: var(--rs-primary-800);
    --rs-btn-ghost-active-border: var(--rs-primary-900);
    --rs-btn-ghost-active-text: var(--rs-primary-900);
    --rs-btn-link-text: var(--rs-primary-700);
    --rs-btn-link-hover-text: var(--rs-primary-800);
    --rs-btn-link-active-text: var(--rs-primary-900);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor: var(--rs-gray-500);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor-inverse: var(--rs-gray-0);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-listbox-option-hover-bg: rgb(from var(--rs-primary-100) r g b / 50%);
    --rs-listbox-option-hover-text: var(--rs-primary-700);
    --rs-calendar-today-bg: var(--rs-primary-500);
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgb(from var(--rs-primary-100) r g b / 50%);
    --rs-calendar-time-unit-bg: var(--rs-gray-50);
    --rs-calendar-date-selected-text: #fff;
}
@supports not (color: rgb(from white r g b)) {
    :root,
    .rs-theme-light {
        --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
        --rs-loader-ring: rgba(247, 247, 250, 0.8);
        --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
        --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
        --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
    }
}
.rs-theme-dark {
    --rs-gray-0: #fff;
    --rs-gray-50: #e9ebf0;
    --rs-gray-100: #cbced4;
    --rs-gray-200: #a4a9b3;
    --rs-gray-300: #858b94;
    --rs-gray-400: #6a6f76;
    --rs-gray-500: #5c6066;
    --rs-gray-600: #3c3f43;
    --rs-gray-700: #292d33;
    --rs-gray-800: #1a1d24;
    --rs-gray-900: #0f131a;
    --rs-primary-50: #f2fcff;
    --rs-primary-100: #ccf3ff;
    --rs-primary-200: #a6e9ff;
    --rs-primary-400: #59d0ff;
    --rs-primary-500: #34c3ff;
    --rs-primary-600: #25b3f5;
    --rs-primary-700: #169de0;
    --rs-primary-800: #0a81c2;
    --rs-primary-900: #006199;
    --rs-color-red: #f04f43;
    --rs-red-400: #f26a63;
    --rs-red-500: #f04f43;
    --rs-red-600: #e63f30;
    --rs-orange-400: #ffa930;
    --rs-orange-500: #ff9800;
    --rs-orange-600: #f59700;
    --rs-yellow-400: #ffcf75;
    --rs-yellow-500: #ffc757;
    --rs-yellow-600: #f5bb3d;
    --rs-green-400: #71bf72;
    --rs-green-500: #58b15b;
    --rs-green-600: #3fab45;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-blue-400: #3dadf2;
    --rs-blue-500: #1499ef;
    --rs-blue-600: #0e8ce6;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-text-primary: var(--rs-gray-50);
    --rs-text-secondary: var(--rs-gray-200);
    --rs-text-active: var(--rs-primary-500);
    --rs-text-disabled: var(--rs-gray-400);
    --rs-border-primary: var(--rs-gray-600);
    --rs-border-secondary: var(--rs-gray-700);
    --rs-bg-overlay: var(--rs-gray-700);
    --rs-bg-well: var(--rs-gray-900);
    --rs-bg-active: var(--rs-primary-700);
    --rs-state-hover-bg: var(--rs-gray-600);
    --rs-color-focus-ring: 0 0 0 3px rgb(from var(--rs-gray-500) r g b / 25%);
    --rs-btn-default-bg: var(--rs-gray-600);
    --rs-btn-default-text: var(--rs-gray-50);
    --rs-btn-default-hover-bg: var(--rs-gray-500);
    --rs-btn-default-active-bg: var(--rs-gray-300);
    --rs-btn-default-active-text: var(--rs-gray-0);
    --rs-btn-default-disabled-bg: var(--rs-gray-600);
    --rs-btn-default-disabled-text: var(--rs-gray-400);
    --rs-btn-primary-bg: var(--rs-primary-700);
    --rs-btn-primary-text: var(--rs-gray-0);
    --rs-btn-primary-hover-bg: var(--rs-primary-600);
    --rs-btn-primary-active-bg: var(--rs-primary-400);
    --rs-btn-subtle-text: var(--rs-gray-200);
    --rs-btn-subtle-hover-bg: var(--rs-gray-500);
    --rs-btn-subtle-hover-text: var(--rs-gray-50);
    --rs-btn-subtle-active-bg: var(--rs-gray-400);
    --rs-btn-subtle-active-text: var(--rs-gray-0);
    --rs-btn-subtle-disabled-text: var(--rs-gray-500);
    --rs-btn-ghost-border: var(--rs-primary-500);
    --rs-btn-ghost-text: var(--rs-primary-500);
    --rs-btn-ghost-hover-border: var(--rs-primary-400);
    --rs-btn-ghost-hover-text: var(--rs-primary-400);
    --rs-btn-ghost-active-border: var(--rs-primary-200);
    --rs-btn-ghost-active-text: var(--rs-primary-200);
    --rs-btn-link-text: var(--rs-primary-500);
    --rs-btn-link-hover-text: var(--rs-primary-400);
    --rs-btn-link-active-text: var(--rs-primary-200);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor: var(--rs-gray-0);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor-inverse: var(--rs-gray-500);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-listbox-option-hover-bg: var(--rs-gray-600);
    --rs-listbox-option-hover-text: currentColor;
    --rs-calendar-today-bg: var(--rs-primary-700);
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgb(from var(--rs-primary-900) r g b / 50%);
    --rs-calendar-time-unit-bg: var(--rs-gray-600);
    --rs-calendar-date-selected-text: #fff;
}
@supports not (color: rgb(from white r g b)) {
    .rs-theme-dark {
        --rs-color-focus-ring: 0 0 0 3px rgba(52, 195, 255, 0.25);
        --rs-loader-ring: rgba(233, 235, 240, 0.3);
        --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
        --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
    }
}
.rs-theme-high-contrast {
    --rs-gray-0: #fff;
    --rs-gray-50: #e9ebf0;
    --rs-gray-100: #cbced4;
    --rs-gray-200: #a4a9b3;
    --rs-gray-300: #858b94;
    --rs-gray-400: #6a6f76;
    --rs-gray-500: #5c6066;
    --rs-gray-600: #3c3f43;
    --rs-gray-700: #292d33;
    --rs-gray-800: #1a1d24;
    --rs-gray-900: #0f131a;
    --rs-primary-50: #fffef2;
    --rs-primary-100: #fffbc2;
    --rs-primary-200: #fffa91;
    --rs-primary-400: #fffc30;
    --rs-primary-500: #ffff00;
    --rs-primary-600: #f1f500;
    --rs-primary-700: #d9e000;
    --rs-primary-800: #b8c200;
    --rs-primary-900: #8f9900;
    --rs-color-red: #bd1732;
    --rs-red-400: #c93a55;
    --rs-red-500: #bd1732;
    --rs-red-600: #b51029;
    --rs-orange-400: #ffa930;
    --rs-orange-500: #ff9800;
    --rs-orange-600: #f59700;
    --rs-yellow-400: #ffcf75;
    --rs-yellow-500: #ffc757;
    --rs-yellow-600: #f5bb3d;
    --rs-green-400: #2a9c46;
    --rs-green-500: #0d822c;
    --rs-green-600: #09802b;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-blue-400: #3dadf2;
    --rs-blue-500: #1499ef;
    --rs-blue-600: #0e8ce6;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-text-primary: var(--rs-gray-50);
    --rs-text-secondary: var(--rs-gray-200);
    --rs-text-active: var(--rs-primary-500);
    --rs-text-disabled: var(--rs-gray-400);
    --rs-border-primary: var(--rs-gray-100);
    --rs-border-secondary: var(--rs-gray-700);
    --rs-bg-overlay: var(--rs-gray-800);
    --rs-bg-well: var(--rs-gray-900);
    --rs-bg-active: var(--rs-primary-500);
    --rs-state-hover-bg: var(--rs-gray-600);
    --rs-color-focus-ring: var(--rs-gray-0);
    --rs-btn-default-bg: transparent;
    --rs-btn-default-text: var(--rs-primary-500);
    --rs-btn-default-border: 1px solid var(--rs-primary-500);
    --rs-btn-default-hover-bg: transparent;
    --rs-btn-default-hover-text: var(--rs-primary-400);
    --rs-btn-default-active-bg: transparent;
    --rs-btn-default-active-text: var(--rs-primary-200);
    --rs-btn-default-disabled-bg: transparent;
    --rs-btn-default-disabled-text: var(--rs-primary-900);
    --rs-btn-default-disabled-boreder-color: var(--rs-gray-100);
    --rs-btn-primary-bg: var(--rs-primary-500);
    --rs-btn-primary-text: var(--rs-gray-900);
    --rs-btn-primary-hover-bg: var(--rs-primary-400);
    --rs-btn-primary-active-bg: var(--rs-primary-200);
    --rs-btn-subtle-text: var(--rs-primary-500);
    --rs-btn-subtle-hover-bg: transparent;
    --rs-btn-subtle-hover-text: var(--rs-primary-400);
    --rs-btn-subtle-active-bg: transparent;
    --rs-btn-subtle-active-text: var(--rs-primary-200);
    --rs-btn-subtle-disabled-text: var(--rs-gray-500);
    --rs-btn-ghost-border: var(--rs-primary-500);
    --rs-btn-ghost-text: var(--rs-primary-500);
    --rs-btn-ghost-hover-border: var(--rs-primary-400);
    --rs-btn-ghost-hover-text: var(--rs-primary-400);
    --rs-btn-ghost-active-border: var(--rs-primary-200);
    --rs-btn-ghost-active-text: var(--rs-primary-200);
    --rs-btn-link-text: var(--rs-primary-500);
    --rs-btn-link-hover-text: var(--rs-primary-400);
    --rs-btn-link-active-text: var(--rs-primary-200);
    --rs-loader-ring: rgb(from var(--rs-gray-50) r g b / 30%);
    --rs-loader-rotor: var(--rs-gray-0);
    --rs-loader-ring-inverse: rgb(from var(--rs-gray-50) r g b / 80%);
    --rs-loader-rotor-inverse: var(--rs-gray-500);
    --rs-input-focus-border: var(--rs-primary-500);
    --rs-listbox-option-hover-bg: transparent;
    --rs-listbox-option-hover-text: var(--rs-primary-500);
    --rs-calendar-today-bg: var(--rs-primary-500);
    --rs-calendar-today-text: var(--rs-gray-900);
    --rs-calendar-range-bg: rgb(from var(--rs-primary-900) r g b / 50%);
    --rs-calendar-time-unit-bg: var(--rs-gray-900);
    --rs-calendar-date-selected-text: var(--rs-gray-900);
}
@supports not (color: rgb(from white r g b)) {
    .rs-theme-high-contrast {
        --rs-loader-ring: rgba(233, 235, 240, 0.3);
        --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
        --rs-calendar-range-bg: rgba(143, 153, 0, 0.5);
    }
}
/* stylelint-disable */
*[class*='rs-'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
*[class*='rs-']::before,
*[class*='rs-']::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.rs-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    -webkit-transition: color 0.15s ease-out, background-color 0.15s ease-out;
    transition: color 0.15s ease-out, background-color 0.15s ease-out;
    border: none;
    border: var(--rs-btn-default-border, none);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    color: #575757;
    color: var(--rs-btn-default-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
    overflow: hidden;
    position: relative;
}
.rs-theme-high-contrast .rs-btn {
    -webkit-transition: none;
    transition: none;
}
.rs-btn-ghost.rs-btn {
    padding: 7px 11px;
}
.rs-btn-icon.rs-btn {
    padding: 10px 10px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn:focus-visible {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-btn:focus-visible {
    outline-offset: 2px;
}
.rs-btn:hover,
.rs-btn:focus {
    color: var(--rs-btn-default-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-default-hover-bg);
    text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
    color: #272c36;
    color: var(--rs-btn-default-active-text);
    background-color: #d9d9d9;
    background-color: var(--rs-btn-default-active-bg);
}
.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
    cursor: not-allowed;
    color: #8e8e93;
    color: var(--rs-btn-default-disabled-text);
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-btn:disabled,
.rs-theme-high-contrast .rs-btn.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
@media not all and (min-resolution: 0.001dpcm) {
    .rs-btn {
        /* stylelint-disable */
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        /* stylelint-enable */
    }
}
.rs-btn-start-icon {
    line-height: 0;
    margin-right: 5px;
}
.rs-btn-end-icon {
    line-height: 0;
    margin-left: 5px;
}
.rs-btn-primary {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    border: none;
}
.rs-btn-primary:hover,
.rs-btn-primary:focus {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #2589f5;
    background-color: var(--rs-btn-primary-hover-bg);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #1675e0;
    background-color: var(--rs-btn-primary-active-bg);
}
.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
    color: #fff;
    color: var(--rs-btn-primary-text);
    background-color: #3498ff;
    background-color: var(--rs-btn-primary-bg);
    opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-subtle {
    color: #575757;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
}
.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
}
.rs-btn-subtle:disabled,
.rs-btn-subtle.rs-btn-disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
}
.rs-theme-high-contrast .rs-btn-subtle:disabled,
.rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-link {
    color: #1675e0;
    color: var(--rs-btn-link-text);
    background-color: transparent;
    border: none;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: underline;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
    color: #004299;
    color: var(--rs-btn-link-active-text);
    background-color: transparent;
}
.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
    color: #0a5dc2;
    color: var(--rs-btn-link-hover-text);
    background-color: transparent;
    text-decoration: none;
    opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-link:disabled,
.rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-ghost {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    border: 1px solid #1675e0;
    border: 1px solid var(--rs-btn-ghost-border);
}
.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
    color: #0a5dc2;
    color: var(--rs-btn-ghost-hover-text);
    background-color: transparent;
    border-color: #0a5dc2;
    border-color: var(--rs-btn-ghost-hover-border);
    -webkit-box-shadow: 0 0 0 1px #0a5dc2;
    -webkit-box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
    box-shadow: 0 0 0 1px #0a5dc2;
    box-shadow: 0 0 0 1px var(--rs-btn-ghost-hover-border);
}
.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active {
    color: #004299;
    color: var(--rs-btn-ghost-active-text);
    background-color: transparent;
    border-color: #004299;
    border-color: var(--rs-btn-ghost-active-border);
}
.rs-btn-ghost:disabled,
.rs-btn-ghost.rs-btn-disabled {
    color: #1675e0;
    color: var(--rs-btn-ghost-text);
    background-color: transparent;
    opacity: 0.3;
    border-color: #1675e0;
    border-color: var(--rs-btn-ghost-border);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-theme-high-contrast .rs-btn-ghost:disabled,
.rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-500);
    --rs-btn-primary-hover-bg: var(--rs-red-700);
    --rs-btn-primary-active-bg: var(--rs-red-800);
    --rs-btn-subtle-hover-bg: var(--rs-red-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-red-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-red-700);
    --rs-btn-ghost-text: var(--rs-red-700);
    --rs-btn-ghost-hover-border: var(--rs-red-900);
    --rs-btn-ghost-hover-text: var(--rs-red-800);
    --rs-btn-ghost-active-border: var(--rs-red-900);
    --rs-btn-ghost-active-text: var(--rs-red-900);
    --rs-btn-link-text: var(--rs-red-700);
    --rs-btn-link-hover-text: var(--rs-red-800);
    --rs-btn-link-active-text: var(--rs-red-900);
}
.rs-theme-dark .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-500);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-theme-high-contrast .rs-btn-red {
    --rs-btn-primary-bg: var(--rs-red-700);
    --rs-btn-primary-hover-bg: var(--rs-red-600);
    --rs-btn-primary-active-bg: var(--rs-red-400);
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-red-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-red-500);
    --rs-btn-ghost-text: var(--rs-red-500);
    --rs-btn-ghost-hover-border: var(--rs-red-400);
    --rs-btn-ghost-hover-text: var(--rs-red-400);
    --rs-btn-ghost-active-border: var(--rs-red-200);
    --rs-btn-ghost-active-text: var(--rs-red-200);
    --rs-btn-link-text: var(--rs-red-500);
    --rs-btn-link-hover-text: var(--rs-red-400);
    --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-500);
    --rs-btn-primary-hover-bg: var(--rs-orange-700);
    --rs-btn-primary-active-bg: var(--rs-orange-800);
    --rs-btn-subtle-hover-bg: var(--rs-orange-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-orange-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-orange-700);
    --rs-btn-ghost-text: var(--rs-orange-700);
    --rs-btn-ghost-hover-border: var(--rs-orange-900);
    --rs-btn-ghost-hover-text: var(--rs-orange-800);
    --rs-btn-ghost-active-border: var(--rs-orange-900);
    --rs-btn-ghost-active-text: var(--rs-orange-900);
    --rs-btn-link-text: var(--rs-orange-700);
    --rs-btn-link-hover-text: var(--rs-orange-800);
    --rs-btn-link-active-text: var(--rs-orange-900);
}
.rs-theme-dark .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-500);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-theme-high-contrast .rs-btn-orange {
    --rs-btn-primary-bg: var(--rs-orange-700);
    --rs-btn-primary-hover-bg: var(--rs-orange-600);
    --rs-btn-primary-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-hover-bg: var(--rs-orange-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-orange-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-orange-500);
    --rs-btn-ghost-text: var(--rs-orange-500);
    --rs-btn-ghost-hover-border: var(--rs-orange-400);
    --rs-btn-ghost-hover-text: var(--rs-orange-400);
    --rs-btn-ghost-active-border: var(--rs-orange-200);
    --rs-btn-ghost-active-text: var(--rs-orange-200);
    --rs-btn-link-text: var(--rs-orange-500);
    --rs-btn-link-hover-text: var(--rs-orange-400);
    --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-500);
    --rs-btn-primary-hover-bg: var(--rs-yellow-700);
    --rs-btn-primary-active-bg: var(--rs-yellow-800);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-yellow-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-yellow-700);
    --rs-btn-ghost-text: var(--rs-yellow-700);
    --rs-btn-ghost-hover-border: var(--rs-yellow-900);
    --rs-btn-ghost-hover-text: var(--rs-yellow-800);
    --rs-btn-ghost-active-border: var(--rs-yellow-900);
    --rs-btn-ghost-active-text: var(--rs-yellow-900);
    --rs-btn-link-text: var(--rs-yellow-700);
    --rs-btn-link-hover-text: var(--rs-yellow-800);
    --rs-btn-link-active-text: var(--rs-yellow-900);
}
.rs-theme-dark .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-500);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-theme-high-contrast .rs-btn-yellow {
    --rs-btn-primary-bg: var(--rs-yellow-700);
    --rs-btn-primary-hover-bg: var(--rs-yellow-600);
    --rs-btn-primary-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-yellow-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-yellow-500);
    --rs-btn-ghost-text: var(--rs-yellow-500);
    --rs-btn-ghost-hover-border: var(--rs-yellow-400);
    --rs-btn-ghost-hover-text: var(--rs-yellow-400);
    --rs-btn-ghost-active-border: var(--rs-yellow-200);
    --rs-btn-ghost-active-text: var(--rs-yellow-200);
    --rs-btn-link-text: var(--rs-yellow-500);
    --rs-btn-link-hover-text: var(--rs-yellow-400);
    --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-500);
    --rs-btn-primary-hover-bg: var(--rs-green-700);
    --rs-btn-primary-active-bg: var(--rs-green-800);
    --rs-btn-subtle-hover-bg: var(--rs-green-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-green-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-green-700);
    --rs-btn-ghost-text: var(--rs-green-700);
    --rs-btn-ghost-hover-border: var(--rs-green-900);
    --rs-btn-ghost-hover-text: var(--rs-green-800);
    --rs-btn-ghost-active-border: var(--rs-green-900);
    --rs-btn-ghost-active-text: var(--rs-green-900);
    --rs-btn-link-text: var(--rs-green-700);
    --rs-btn-link-hover-text: var(--rs-green-800);
    --rs-btn-link-active-text: var(--rs-green-900);
}
.rs-theme-dark .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-500);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-theme-high-contrast .rs-btn-green {
    --rs-btn-primary-bg: var(--rs-green-700);
    --rs-btn-primary-hover-bg: var(--rs-green-600);
    --rs-btn-primary-active-bg: var(--rs-green-400);
    --rs-btn-subtle-hover-bg: var(--rs-green-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-green-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-green-500);
    --rs-btn-ghost-text: var(--rs-green-500);
    --rs-btn-ghost-hover-border: var(--rs-green-400);
    --rs-btn-ghost-hover-text: var(--rs-green-400);
    --rs-btn-ghost-active-border: var(--rs-green-200);
    --rs-btn-ghost-active-text: var(--rs-green-200);
    --rs-btn-link-text: var(--rs-green-500);
    --rs-btn-link-hover-text: var(--rs-green-400);
    --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-500);
    --rs-btn-primary-hover-bg: var(--rs-cyan-700);
    --rs-btn-primary-active-bg: var(--rs-cyan-800);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-cyan-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-cyan-700);
    --rs-btn-ghost-text: var(--rs-cyan-700);
    --rs-btn-ghost-hover-border: var(--rs-cyan-900);
    --rs-btn-ghost-hover-text: var(--rs-cyan-800);
    --rs-btn-ghost-active-border: var(--rs-cyan-900);
    --rs-btn-ghost-active-text: var(--rs-cyan-900);
    --rs-btn-link-text: var(--rs-cyan-700);
    --rs-btn-link-hover-text: var(--rs-cyan-800);
    --rs-btn-link-active-text: var(--rs-cyan-900);
}
.rs-theme-dark .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-500);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-theme-high-contrast .rs-btn-cyan {
    --rs-btn-primary-bg: var(--rs-cyan-700);
    --rs-btn-primary-hover-bg: var(--rs-cyan-600);
    --rs-btn-primary-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-cyan-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-cyan-500);
    --rs-btn-ghost-text: var(--rs-cyan-500);
    --rs-btn-ghost-hover-border: var(--rs-cyan-400);
    --rs-btn-ghost-hover-text: var(--rs-cyan-400);
    --rs-btn-ghost-active-border: var(--rs-cyan-200);
    --rs-btn-ghost-active-text: var(--rs-cyan-200);
    --rs-btn-link-text: var(--rs-cyan-500);
    --rs-btn-link-hover-text: var(--rs-cyan-400);
    --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-500);
    --rs-btn-primary-hover-bg: var(--rs-blue-700);
    --rs-btn-primary-active-bg: var(--rs-blue-800);
    --rs-btn-subtle-hover-bg: var(--rs-blue-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-blue-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-blue-700);
    --rs-btn-ghost-text: var(--rs-blue-700);
    --rs-btn-ghost-hover-border: var(--rs-blue-900);
    --rs-btn-ghost-hover-text: var(--rs-blue-800);
    --rs-btn-ghost-active-border: var(--rs-blue-900);
    --rs-btn-ghost-active-text: var(--rs-blue-900);
    --rs-btn-link-text: var(--rs-blue-700);
    --rs-btn-link-hover-text: var(--rs-blue-800);
    --rs-btn-link-active-text: var(--rs-blue-900);
}
.rs-theme-dark .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-500);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-theme-high-contrast .rs-btn-blue {
    --rs-btn-primary-bg: var(--rs-blue-700);
    --rs-btn-primary-hover-bg: var(--rs-blue-600);
    --rs-btn-primary-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-hover-bg: var(--rs-blue-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-blue-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-blue-500);
    --rs-btn-ghost-text: var(--rs-blue-500);
    --rs-btn-ghost-hover-border: var(--rs-blue-400);
    --rs-btn-ghost-hover-text: var(--rs-blue-400);
    --rs-btn-ghost-active-border: var(--rs-blue-200);
    --rs-btn-ghost-active-text: var(--rs-blue-200);
    --rs-btn-link-text: var(--rs-blue-500);
    --rs-btn-link-hover-text: var(--rs-blue-400);
    --rs-btn-link-active-text: var(--rs-blue-200);
}
.rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-500);
    --rs-btn-primary-hover-bg: var(--rs-violet-700);
    --rs-btn-primary-active-bg: var(--rs-violet-800);
    --rs-btn-subtle-hover-bg: var(--rs-violet-500);
    --rs-btn-subtle-hover-text: #e5e5ea;
    --rs-btn-subtle-active-bg: var(--rs-violet-600);
    --rs-btn-subtle-active-text: #575757;
    --rs-btn-ghost-border: var(--rs-violet-700);
    --rs-btn-ghost-text: var(--rs-violet-700);
    --rs-btn-ghost-hover-border: var(--rs-violet-900);
    --rs-btn-ghost-hover-text: var(--rs-violet-800);
    --rs-btn-ghost-active-border: var(--rs-violet-900);
    --rs-btn-ghost-active-text: var(--rs-violet-900);
    --rs-btn-link-text: var(--rs-violet-700);
    --rs-btn-link-hover-text: var(--rs-violet-800);
    --rs-btn-link-active-text: var(--rs-violet-900);
}
.rs-theme-dark .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-500);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-theme-high-contrast .rs-btn-violet {
    --rs-btn-primary-bg: var(--rs-violet-700);
    --rs-btn-primary-hover-bg: var(--rs-violet-600);
    --rs-btn-primary-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-hover-bg: var(--rs-violet-600);
    --rs-btn-subtle-hover-text: var(--rs-gray-900);
    --rs-btn-subtle-active-bg: var(--rs-violet-400);
    --rs-btn-subtle-active-text: var(--rs-gray-900);
    --rs-btn-ghost-border: var(--rs-violet-500);
    --rs-btn-ghost-text: var(--rs-violet-500);
    --rs-btn-ghost-hover-border: var(--rs-violet-400);
    --rs-btn-ghost-hover-text: var(--rs-violet-400);
    --rs-btn-ghost-active-border: var(--rs-violet-200);
    --rs-btn-ghost-active-text: var(--rs-violet-200);
    --rs-btn-link-text: var(--rs-violet-500);
    --rs-btn-link-hover-text: var(--rs-violet-400);
    --rs-btn-link-active-text: var(--rs-violet-200);
}
.rs-btn-lg {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 16px;
}
.rs-btn-ghost.rs-btn-lg {
    padding: 9px 15px;
}
.rs-btn-icon.rs-btn-lg {
    padding: 11px 11px;
    line-height: 20px;
}
.rs-btn-icon.rs-btn-lg > .rs-icon {
    font-size: 20px;
}
.rs-btn-icon-with-text.rs-btn-lg {
    line-height: 22px;
}
.rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
    padding: 11px 11px;
    width: 42px;
    height: 42px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
    padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
    padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-md {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px;
}
.rs-btn-ghost.rs-btn-md {
    padding: 7px 11px;
}
.rs-btn-icon.rs-btn-md {
    padding: 10px 10px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn-md > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-md {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-md > .rs-icon {
    padding: 10px 10px;
    width: 36px;
    height: 36px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
    padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
    padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-sm {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;
}
.rs-btn-ghost.rs-btn-sm {
    padding: 4px 9px;
}
.rs-btn-icon.rs-btn-sm {
    padding: 7px 7px;
    line-height: 16px;
}
.rs-btn-icon.rs-btn-sm > .rs-icon {
    font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-sm {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
    padding: 7px 7px;
    width: 30px;
    height: 30px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
    padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
    padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-xs {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
}
.rs-btn-ghost.rs-btn-xs {
    padding: 1px 7px;
}
.rs-btn-icon.rs-btn-xs {
    padding: 6px 6px;
    line-height: 12px;
}
.rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 12px;
}
.rs-btn-icon-with-text.rs-btn-xs {
    line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
    padding: 6px 6px;
    width: 24px;
    height: 24px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
    padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
    left: 0;
    border-right: none;
    border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
    padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
    right: 0;
    border-left: none;
    border-left: var(--rs-btn-default-border, none);
}
.rs-btn-block {
    display: block;
    width: 100%;
}
.rs-btn-block + .rs-btn-block {
    margin-top: 5px;
}
.rs-btn-loading {
    color: transparent !important;
    position: relative;
    cursor: default;
    pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    z-index: 1;
}
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
    width: 16px;
    height: 16px;
}
.rs-btn-loading > .rs-btn-spin::before {
    border: 3px solid rgb(from #f7f7fa r g b / 80%);
    border: 3px solid var(--rs-loader-ring);
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgba(248, 247, 250, 0.3);
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    border-color: rgb(from #f7f7fa r g b / 30%);
    border-color: var(--rs-loader-ring-inverse);
}
.rs-btn-loading > .rs-btn-spin::after {
    border-width: 3px;
    border-color: #a6a6a6 transparent transparent;
    border-color: var(--rs-loader-rotor) transparent transparent;
    border-style: solid;
    -webkit-animation: buttonSpin 0.6s infinite linear;
    animation: buttonSpin 0.6s infinite linear;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
    border-top-color: var(--rs-loader-rotor-inverse);
}
@-webkit-keyframes buttonSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes buttonSpin {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rs-picker-subtle .picker-subtle-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
    background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
    display: none;
}
.rs-calendar {
    padding-top: 12px;
    position: relative;
    min-height: 266px;
    overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
}
.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-header-cell {
    border-bottom: 1px solid #f2f2f5;
    border-bottom: 1px solid var(--rs-border-secondary);
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
}
.rs-calendar-panel .rs-calendar-header {
    width: 100%;
    padding-bottom: 6px;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
    content: ' ';
    display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
    clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
    float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
    float: none !important;
}
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-backward {
    display: inline-block;
    visibility: hidden;
    pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
    width: auto !important;
}
.rs-calendar-panel.rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 0;
    padding-right: 0;
}
.rs-calendar-panel .rs-calendar-month-view {
    padding-left: 0;
    padding-right: 0;
}
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-header-cell-content {
    padding-top: 8px;
    padding-bottom: 8px;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    color: #fff;
    color: var(--rs-calendar-today-text);
    width: 20px;
    height: 20px;
    background-color: #3498ff;
    background-color: var(--rs-calendar-today-bg);
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: inherit;
    color: inherit;
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    box-shadow: inset 0 0 0 1px #3498ff;
    box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-panel .rs-calendar-table-cell-day {
    margin-top: 3px;
    display: block;
    text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
    margin-top: -2px;
    margin-left: 12px;
    margin-right: 12px;
    top: 54px;
    width: calc(100% - 24px);
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
    height: 644px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
    height: 344px;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    width: 100%;
    height: 100px;
    overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
    vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
    padding: 0 1px;
    line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
    width: 100%;
    border-radius: 0;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
    border-bottom-left-radius: 6px;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
    border-bottom-right-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 50px;
}
.rs-calendar-btn-close {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 14px;
    padding: 0;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    background: var(--rs-bg-overlay);
    z-index: 1;
}
.rs-calendar-panel .rs-calendar-btn-close {
    bottom: 11px;
    margin: 0 20px;
}
.rs-calendar-month-view .rs-calendar-header-month-toolbar {
    padding-left: 24px;
    padding-right: 24px;
}
.rs-calendar-month-view .rs-calendar-header-backward,
.rs-calendar-month-view .rs-calendar-header-forward {
    display: none;
}
.rs-calendar-month-view .rs-calendar-header-title-date {
    color: #1675e0;
    color: var(--rs-text-active);
    background: transparent;
}
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error {
    color: #f44336;
    color: var(--rs-text-error);
}
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
    color: #fff !important;
}
.rs-calendar-time-view .rs-calendar-header-title-time {
    color: #1675e0;
    color: var(--rs-text-active);
    background: transparent;
}
.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error {
    color: #f44336;
    color: var(--rs-text-error);
}
.rs-calendar-time-view .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
    color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
    position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
    content: '';
    display: block;
    width: 100%;
    margin-top: 4px;
    height: 24px;
    position: absolute;
    z-index: 0;
    top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
    z-index: 1;
    position: relative;
}
.rs-calendar-table-cell-in-range::before {
    background-color: rgb(from #cce9ff r g b / 50%);
    background-color: var(--rs-calendar-range-bg);
}
.rs-calendar-table-cell-in-range:hover::before {
    display: none;
}
.rs-calendar-month-dropdown-scroll div:focus {
    outline: none;
}
.rs-calendar-header {
    width: 280px;
    padding-left: 12px;
    padding-right: 12px;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
    content: ' ';
    display: table;
}
.rs-calendar-header::after {
    clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
    display: inline-block;
}
.rs-calendar-header-month-toolbar {
    float: left;
}
.rs-calendar-header-time-toolbar {
    float: right;
}
.rs-calendar-header-title {
    margin: 0 4px;
}
.rs-calendar-header-meridian {
    font-size: 12px;
    margin-left: 4px;
}
.rs-calendar-header-error {
    color: #575757;
    color: var(--rs-btn-subtle-text);
    background-color: transparent;
    border: none;
    --rs-btn-subtle-text: var(--rs-red-500);
    --rs-btn-subtle-hover-bg: var(--rs-red-500);
    --rs-btn-subtle-hover-text: #fff;
    --rs-btn-subtle-active-bg: var(--rs-red-600);
    --rs-btn-subtle-active-text: #fff;
}
.rs-calendar-header-error:hover,
.rs-calendar-header-error:focus {
    color: #575757;
    color: var(--rs-btn-subtle-hover-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active {
    color: #272c36;
    color: var(--rs-btn-subtle-active-text);
    background-color: #e5e5ea;
    background-color: var(--rs-btn-subtle-active-bg);
}
.rs-calendar-header-error:disabled,
.rs-calendar-header-error.rs-btn-disabled {
    color: #c5c6c7;
    color: var(--rs-btn-subtle-disabled-text);
    background: none;
}
.rs-theme-high-contrast .rs-calendar-header-error:disabled,
.rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled {
    border-color: var(--rs-btn-default-disabled-boreder-color);
}
.rs-theme-dark .rs-calendar-header-error {
    --rs-btn-subtle-hover-bg: var(--rs-red-600);
    --rs-btn-subtle-active-bg: var(--rs-red-400);
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
    cursor: not-allowed;
    opacity: 0.3;
    background: none;
}
.rs-calendar-header-btn-disabled::after {
    display: none !important;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    content: ' ';
    display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
    float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
    float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
    margin: 0 auto;
    text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
    float: none;
    display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
    margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward .rs-icon,
[dir='rtl'] .rs-calendar-header-forward .rs-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-body {
    padding: 4px 12px 12px;
}
.rs-calendar-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.rs-calendar-table-row {
    display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
    display: table-cell;
    width: 1%;
    padding: 1px 0;
    text-align: center;
    vertical-align: middle;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-un-same-month .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
    color: #8e8e93;
    color: var(--rs-text-disabled);
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content:hover,
.rs-theme-high-contrast .rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-is-today.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content:hover {
    text-decoration: underline;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled.rs-calendar-table-header-cell-selected .rs-calendar-table-header-cell-content {
    opacity: 0.3;
}
.rs-calendar-table-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
}
.rs-calendar-table-cell-content:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    -webkit-box-shadow: inset 0 0 0 1px #3498ff;
    -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
    box-shadow: inset 0 0 0 1px #3498ff;
    box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-theme-high-contrast .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    text-decoration: underline;
}
.rs-calendar-table-header-cell-content {
    display: inline-block;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
}
.rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-content:focus,
.rs-calendar-table-header-cell-content:hover:focus {
    background: none;
    cursor: auto;
}
.rs-calendar-table-cell-week-number {
    display: table-cell;
    min-width: 30px;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
    color: #8e8e93;
    color: var(--rs-text-secondary);
    background-color: #f7f7fa;
    background-color: var(--rs-bg-well);
    font-size: 12px;
}
.rs-calendar-month-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    border-top: 1px solid #e5e5ea;
    border-top: 1px solid var(--rs-border-primary);
    margin-top: 0;
    width: 100%;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    overflow: hidden;
    z-index: 1;
}
.rs-calendar-month-view .rs-calendar-month-dropdown {
    display: block;
}
.rs-calendar-month-view .rs-calendar-month-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
    animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
    height: 230px;
}
.rs-calendar-month-dropdown-row {
    position: relative;
    padding-left: 50px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 1px dashed #e5e5ea;
    border-bottom: 1px dashed var(--rs-border-primary);
}
.rs-calendar-month-dropdown-year {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 12px;
}
.rs-calendar-month-dropdown-year-active {
    color: #1675e0;
    color: var(--rs-text-active);
}
.rs-calendar-month-dropdown-list {
    display: block;
    min-width: 200px;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
    content: ' ';
    display: table;
}
.rs-calendar-month-dropdown-list::after {
    clear: both;
}
.rs-calendar-month-dropdown-cell {
    display: inline-block;
    float: left;
    width: calc((100% - 1px * 12 ) / 6);
    margin: 1px;
    text-align: center;
    vertical-align: middle;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-calendar-month-dropdown-cell {
        width: calc((100% - 1px * 12 - 1px) / 6);
    }
}
@supports (-ms-ime-align: auto) {
    .rs-calendar-month-dropdown-cell {
        width: calc((100% - 1px * 12 - 1px) / 6);
    }
}
.rs-calendar-month-dropdown-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
    width: 30px;
    height: 30px;
}
.rs-calendar-month-dropdown-cell-content:hover {
    background-color: #f2faff;
    background-color: var(--rs-state-hover-bg);
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
    color: #8e8e93;
    color: var(--rs-text-disabled);
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
    opacity: 0.3;
    cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.rs-theme-high-contrast .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    text-decoration: underline;
}
.rs-calendar-time-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    background-color: #fff;
    background-color: var(--rs-bg-overlay);
    color: #575757;
    color: var(--rs-text-primary);
    overflow: hidden;
    z-index: 1;
}
.rs-calendar-time-view .rs-calendar-time-dropdown {
    display: block;
}
.rs-calendar-time-view .rs-calendar-time-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
    animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
    display: table;
    width: 100%;
}
.rs-calendar-time-dropdown-column {
    display: table-cell;
    width: 1%;
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
    height: 230px;
    overflow-y: auto;
    padding-bottom: 200px;
}
.rs-calendar-time-dropdown-column-title {
    width: 100%;
    background-color: #f7f7fa;
    background-color: var(--rs-calendar-time-unit-bg);
    color: #8e8e93;
    color: var(--rs-text-secondary);
    text-align: center;
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
}
.rs-calendar-time-dropdown-cell {
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    padding: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
    text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
    background-color: rgb(from #cce9ff r g b / 50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
    outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: #3498ff;
    background-color: var(--rs-bg-active);
    color: #fff;
    color: var(--rs-calendar-date-selected-text);
}
.rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    background-color: rgb(from #cce9ff r g b / 50%);
    background-color: var(--rs-listbox-option-hover-bg);
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    outline: 3px solid rgb(from #3498ff r g b / 25%);
    outline: 3px solid var(--rs-color-focus-ring);
    outline-offset: -3px;
    outline-width: 2px;
    color: #1675e0;
    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-theme-high-contrast .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
    outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
    color: #8e8e93;
    color: var(--rs-text-disabled);
    background: none;
    cursor: not-allowed;
    text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
    opacity: 0.3;
    cursor: not-allowed;
}
@-webkit-keyframes slideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}


